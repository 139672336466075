<script lang="ts" setup>
import type { Order } from '#gql/default'

interface Props {
  order: Order
}

defineProps<Props>()

const route = useRoute()

useLegacyWidget({
  iframeName: 'kinoheld-widget',
  cinemaPath: `user/order?n=${route.params.orderNumber}&key=${route.params.securityKey}`,
})

defineOptions({
  name: 'WidgetOrder',
})
</script>

<template>
  <div>
    <div id="kinoheld-eframe" />
  </div>
</template>
